import { useMemo } from 'react';

import { useActions, useTypedSelector } from '../../../hooks';
import { WidgetModule } from '../../widgetModule';
import { LegendInviteButton } from '../../../components';

export const useTimeDashboardData = () => {
  const { dashboards } = useTypedSelector(state => state.user);
  const { selectedCompany, mainDashboardFilters } = useTypedSelector(state => state.helper);

  const { updateUserDashboards, updateMainDashboardFilters } = useActions();

  const contextOptions = useMemo(
    () => ({
      dashboards,
      name: 'Legend',
      selectedCompanyId: selectedCompany?.uuid ?? '',
      permissions: [],
      mainDashboardFilters,
      timePermission: true,
      teamPermission: true,
      updateMainDashboardFilters,
      inviteButtonComponent: LegendInviteButton,
      updateUserDashboards,
      widgetComponent: WidgetModule,
    }),
    [
      dashboards,
      selectedCompany?.uuid,
      mainDashboardFilters,
      updateUserDashboards,
      updateMainDashboardFilters,
    ]
  );
  return {
    contextOptions,
  };
};
