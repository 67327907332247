import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useForm } from 'react-hook-form';

import { useBooleanState } from '@vyce/core/src/hooks';
import {
  deleteAllShiftsWorkerRequest,
  deleteShiftWorkerRequest,
  getLocationUsersRequest,
  getCompaniesLocationWorkersSharedData,
} from '@vyce/core/src/api/time';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';
import { useTable } from '@vyce/core/src/hooks/useTable';
import { useInfiniteScroll } from '@vyce/core/src/hooks/useInfiniteScroll';
import { GRID_PAGE_SIZE } from '@vyce/core/src/constants';
import { DeviceContext } from '@vyce/core/src/contexts/deviceContext';
import { useMainDashboardFilter } from '@vyce/core/src/hooks/useMainDashboardFilter';

import { ExtendedLocationUserDTO, Props } from '../types';
import { defaultLocationOption, defaultSelectedLocationOption, defaultStatusOption } from '../config';
import { generateExcelFileLink } from '../../../../utils';

export const useWidgetData = ({ selectedCompanyId, mainDashboardFilters }: Props) => {
  const { handleServerError, showNotification } = useContext(NotificationContext);
  const [loading, setLoadingTrue, setLoadingFalse] = useBooleanState(false);
  const [isConfirmDeleteDialogOpen, openConfirmDeleteDialog, closeConfirmDeleteDialog] =
    useBooleanState(false);
  const [downloadButtonLoading, setDownloadButtonLoadingTrue, setDownloadButtonLoadingFalse] =
    useBooleanState(false);
  const [isShowSearchField, setShowSearchFieldTrue] = useBooleanState(false);

  const [selectedStatusId, setSelectedStatusId] = useState(defaultStatusOption.value);
  const [isSmallView, setSmallView] = useState(false);
  const [candidateToRemove, setCandidateToRemove] = useState<{
    user: ExtendedLocationUserDTO;
    location: string;
  } | null>(null);
  const [closeGridAction, setCloseGridAction] = useState<boolean>(false);

  const { total, substring, sortModel, setTotal, handleSearchChange, handleSortModelChange } = useTable({});
  const {
    isLoadingList,
    data,
    setNewData,
    setInitialLoadingStatusesFalse,
    setInitialLoadingStatusesTrue,
    resetLoadingList,
  } = useInfiniteScroll<ExtendedLocationUserDTO>([]);
  const { isMobile } = useContext(DeviceContext);

  const { selectedLocationOptions, unselectedLocationOptions, getLocationsForRequest } =
    useMainDashboardFilter({
      mainDashboardFilters,
      locationOption: defaultLocationOption,
      selectedLocationOption: defaultSelectedLocationOption,
    });
  const methods = useForm<{ location: string }>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      location: defaultLocationOption.value,
    },
  });

  const { watch, setValue } = methods;
  const location = watch('location');

  const resetCloseStatus = () => {
    setCloseGridAction(true);
    setTimeout(() => setCloseGridAction(false), 100);
  };

  const removeWorkerFromOneLocation = async (user: ExtendedLocationUserDTO) => {
    if (!selectedCompanyId || !user.site_id || !user.shift_id || !user.user_id) {
      return;
    }

    try {
      await deleteShiftWorkerRequest(selectedCompanyId, user.site_id, user.shift_id, user.user_id);
      showNotification({ message: 'User has been removed', options: { variant: 'success' } });
      getLocationUsers();
      closeConfirmDialog();
    } catch (e) {
      handleServerError(e);
    }
  };

  const removeWorkerFromAllLocations = async (user: ExtendedLocationUserDTO) => {
    if (!selectedCompanyId || !user.site_id || !user.shift_id || !user.user_id) {
      return;
    }

    try {
      await deleteAllShiftsWorkerRequest(selectedCompanyId, user.user_id);
      showNotification({ message: 'User has been removed', options: { variant: 'success' } });
      getLocationUsers();
      closeConfirmDialog();
    } catch (e) {
      handleServerError(e);
    }
  };

  const handleRemoveUser = useCallback((user: ExtendedLocationUserDTO, location: string) => {
    setCandidateToRemove({ user, location });
    openConfirmDeleteDialog();
    resetCloseStatus();
  }, []);

  const removeUserFromLocation = () => {
    if (candidateToRemove?.location === 'all') {
      removeWorkerFromAllLocations(candidateToRemove.user);
    } else if (candidateToRemove?.user) {
      removeWorkerFromOneLocation(candidateToRemove.user);
    }
  };

  const closeConfirmDialog = () => {
    setCandidateToRemove(null);
    closeConfirmDeleteDialog();
  };

  const getLocationUsers = async (offset: number = 0) => {
    if (!selectedCompanyId) {
      return;
    }

    const itemStatusMap: Record<number, boolean> = setInitialLoadingStatusesFalse(offset, GRID_PAGE_SIZE);

    setLoadingTrue();
    try {
      const { data } = await getLocationUsersRequest({
        companyId: selectedCompanyId,
        data: {
          limit: GRID_PAGE_SIZE,
          offset,
          site_ids: getLocationsForRequest(location),
          status: selectedStatusId !== 'all' ? selectedStatusId : undefined,
          substring,
        },
      });

      const transformedData = data.items.map(item => ({
        ...item,
        locationId: `${item.user_id}_${item.location}`,
      }));

      setTotal(data.count);
      setNewData({ newData: transformedData, offset });

      setInitialLoadingStatusesTrue({ offset, pageSize: GRID_PAGE_SIZE, itemStatusMap });
    } catch (e) {
      handleServerError(e);
    } finally {
      setLoadingFalse();
    }
  };

  const getSharedFile = async () => {
    if (!selectedCompanyId) {
      return;
    }

    setDownloadButtonLoadingTrue();
    try {
      const res = await getCompaniesLocationWorkersSharedData({
        companyId: selectedCompanyId,
        data: {
          limit: GRID_PAGE_SIZE,
          offset: 0,
          //site_ids: getLocationsForRequest(location),
          status: selectedStatusId !== 'all' ? selectedStatusId : undefined,
          substring,
        },
      });

      generateExcelFileLink(res);
    } catch (e) {
      handleServerError(e);
    } finally {
      setDownloadButtonLoadingFalse();
    }
  };

  useEffect(() => {
    getLocationUsers();
  }, [
    selectedCompanyId,
    selectedStatusId,
    location,
    substring,
    selectedLocationOptions,
    unselectedLocationOptions,
  ]);

  useEffect(() => {
    if (selectedLocationOptions.length) {
      setValue('location', selectedLocationOptions[0].value);
    } else {
      setValue('location', unselectedLocationOptions[0].value);
    }
  }, [selectedLocationOptions, unselectedLocationOptions]);

  return {
    locationUsers: data,
    total,
    loading,
    selectedLocationOptions,
    unselectedLocationOptions,
    selectedStatusId,
    methods,
    isConfirmDeleteDialogOpen,
    candidateToRemove,
    closeGridAction,
    isMobile,
    handleSearchChange,
    isLoadingList,
    sortModel,
    isSmallView,
    downloadButtonLoading,
    isShowSearchField,
    setShowSearchFieldTrue,
    getSharedFile,
    setSmallView,
    handleSortModelChange,
    resetLoadingList,
    getLocationUsers,
    handleRemoveUser,
    setSelectedStatusId,
    closeConfirmDialog,
    removeUserFromLocation,
  };
};
