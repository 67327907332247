export const defaultLocationOption = { value: 'all', name: 'All Locations' };
export const defaultSelectedLocationOption = { value: 'selected', name: 'Selected Locations' };
export const defaultStatusOption = {
  value: 'all',
  name: 'any status',
};

export const statusesEnum = {
  all: 'all',
  clocked_in: 'clocked_in',
  clocked_out: 'clocked_out',
  not_clocked_in_today: 'not_clocked_in_today',
};
export const statuses = {
  all: 'any status',
  clocked_in: 'Clocked In',
  clocked_out: 'Clocked Out',
  not_clocked_in_today: 'Hasn’t clocked in today',
} as const;

export const statusOptions = [
  defaultStatusOption,
  {
    value: statusesEnum.clocked_in,
    name: statuses.clocked_in,
  },
  {
    value: statusesEnum.clocked_out,
    name: statuses.clocked_out,
  },
  {
    value: statusesEnum.not_clocked_in_today,
    name: statuses.not_clocked_in_today,
  },
];
