import React from 'react';
import clsx from 'clsx';
import { Box } from '@material-ui/core';

import { statuses, statusesEnum } from '../config';
import { useStyles } from '../styles';

export const SelectorCustomValue = React.memo(
  ({ statusValue }: { statusValue: keyof typeof statusesEnum }) => {
    const classes = useStyles();

    return (
      <Box display="flex" gridGap={8} alignItems="center">
        {statusValue !== statusesEnum.all && (
          <Box
            className={clsx(classes.circle, {
              [classes.primaryBorder]: statusValue === statusesEnum.clocked_in,
              [classes.warningBorder]: statusValue === statusesEnum.not_clocked_in_today,
              [classes.neutralBorder]: statusValue === statusesEnum.clocked_out,
            })}
          />
        )}
        {statuses[statusValue]}
      </Box>
    );
  }
);
