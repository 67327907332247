import { useMemo, useContext } from 'react';

import { TeamsPageContext } from '@vyce/core/src/contexts';

import { LegendInviteButton } from '../../../components';
import { useActions, useTypedSelector } from '../../../hooks';
import { WidgetModule } from '../../widgetModule';

export const useTeamsDashboardData = () => {
  const { dashboards, first_name } = useTypedSelector(state => state.user);
  const { mainDashboardFilters } = useTypedSelector(state => state.helper);
  const { teams, loading, selectedCompanyId = '' } = useContext(TeamsPageContext);
  const { updateUserDashboards, updateMainDashboardFilters } = useActions();

  const contextOptions = useMemo(
    () => ({
      dashboards,
      first_name,
      teams: teams ?? [],
      companyDataLoading: loading,
      selectedCompanyId,
      mainDashboardFilters,
      timePermission: true,
      teamPermission: true,
      updateMainDashboardFilters,
      updateUserDashboards,
      widgetComponent: WidgetModule,
      inviteButtonComponent: LegendInviteButton,
    }),
    [
      dashboards,
      first_name,
      teams,
      loading,
      selectedCompanyId,
      mainDashboardFilters,
      updateUserDashboards,
      updateMainDashboardFilters,
    ]
  );
  return {
    contextOptions,
  };
};
