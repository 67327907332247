export const defaultTeamOption = { value: 'all', name: 'All Teams' };
export const defaultSelectedTeamOption = { value: 'selected', name: 'Selected Teams' };

export const defaultQualificationOption = { value: 'all', name: 'any Qualification' };

export const statuses = {
  Indefinite: 'Indefinite',
  Expired: 'Expired',
  Expiring: 'Expiring',
  Valid: 'Valid',
} as const;

export const defaultStatusOptions = [
  { value: 'all', name: 'any' },
  { value: statuses.Valid, name: 'Valid' },
  { value: statuses.Expiring, name: 'Expiring' },
  { value: statuses.Expired, name: 'Expired' },
];
