import { LegendHelperState } from '@vyce/core/src/types';
import { getFromLS } from '@vyce/core/src/utils/local-storage';

import { LEGEND_NAV_ITEMS } from '../../views/main/constants';
import { HelperAction } from '../actions/helper';
import { ActionType } from '../action-types';

const selectedCompany = getFromLS('selectedCompany');

const initialState: LegendHelperState = {
  userDataLoading: false,
  notification: undefined,
  redirectTo: '',
  navItems: LEGEND_NAV_ITEMS,
  access_token: '',
  companies: [],
  selectedCompany,
  mainDashboardFilters: undefined,
};

const reducer = (state: LegendHelperState = initialState, action: HelperAction): LegendHelperState => {
  switch (action.type) {
    case ActionType.SET_REDIRECT_TO:
      return {
        ...state,
        redirectTo: action.payload,
      };
    case ActionType.CLEAR_REDIRECT_TO:
      return {
        ...state,
        redirectTo: '',
      };
    case ActionType.SET_LOADING:
      return {
        ...state,
        userDataLoading: action.payload,
      };
    case ActionType.SET_ACCESS_TOKEN:
      return {
        ...state,
        access_token: action.payload,
      };
    case ActionType.SET_COMPANIES:
      return {
        ...state,
        companies: action.payload,
      };
    case ActionType.SET_SELECTED_COMPANY:
      return {
        ...state,
        selectedCompany: action.payload,
      };
    case ActionType.CLEAR_HELPERS:
      return initialState;
    case ActionType.UPDATE_MAIN_DASHBOARD_FILTERS:
      return {
        ...state,
        mainDashboardFilters: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
