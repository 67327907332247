import { useContext, useEffect, useMemo, useState } from 'react';

import { useForm } from 'react-hook-form';

import { useBooleanState } from '@vyce/core/src/hooks';
import { getTeamQualificationsRequest, getTeamsQualifications } from '@vyce/core/src/api/teams';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';
import { useTable } from '@vyce/core/src/hooks/useTable';
import { TeamQualificationWidgetDTO } from '@vyce/core/src/api/types';
import { useInfiniteScroll } from '@vyce/core/src/hooks/useInfiniteScroll';
import { GRID_PAGE_SIZE } from '@vyce/core/src/constants';
import { useMainDashboardFilter } from '@vyce/core/src/hooks/useMainDashboardFilter';

import { Props } from '../types';
import {
  defaultQualificationOption,
  defaultStatusOptions,
  defaultTeamOption,
  defaultSelectedTeamOption,
  statuses,
} from '../config';

export const useWidgetData = ({ selectedCompanyId, mainDashboardFilters }: Props) => {
  const { handleServerError } = useContext(NotificationContext);
  const [qualificationsOptions, setQualificationsOptions] = useState<(typeof defaultQualificationOption)[]>(
    []
  );
  const [loading, setLoadingTrue, setLoadingFalse] = useBooleanState(false);
  const [selectedStatusId, setSelectedStatusId] = useState(
    defaultStatusOptions.find(item => item.value === statuses.Expired)?.value
  );

  const { offset, total, setTotal } = useTable({});
  const {
    isLoadingList,
    data,
    setNewData,
    setInitialLoadingStatusesFalse,
    setInitialLoadingStatusesTrue,
    resetLoadingList,
  } = useInfiniteScroll<TeamQualificationWidgetDTO>([]);

  const { selectedTeamOptions, unselectedTeamOptions, getTeamsForRequest } = useMainDashboardFilter({
    mainDashboardFilters,
    locationOption: defaultTeamOption,
    selectedLocationOption: defaultSelectedTeamOption,
  });

  const methods = useForm<{ qualification: typeof defaultQualificationOption; teamId: string }>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      qualification: defaultQualificationOption,
      teamId: defaultTeamOption.value,
    },
  });

  const { watch } = methods;
  const qualification = watch('qualification');
  const teamId = watch('teamId');

  const qualificationsSelectOptions = useMemo(() => {
    if (!qualificationsOptions) return [defaultQualificationOption];
    return [defaultQualificationOption, ...qualificationsOptions];
  }, [qualificationsOptions]);

  const getWidgetSelectorsData = async () => {
    if (!selectedCompanyId) {
      return;
    }

    try {
      const qualificationsData = await getTeamQualificationsRequest(selectedCompanyId);
      setQualificationsOptions(qualificationsData.data.items.map(item => ({ value: item, name: item })));
    } catch (e) {
      handleServerError(e);
    }
  };

  const getTeamQualifications = async (offset: number = 0) => {
    if (!selectedCompanyId) {
      return;
    }

    const itemStatusMap: Record<number, boolean> = setInitialLoadingStatusesFalse(offset, GRID_PAGE_SIZE);

    setLoadingTrue();
    try {
      const res = await getTeamsQualifications({
        companyId: selectedCompanyId,
        data: {
          limit: GRID_PAGE_SIZE,
          offset,
          team_ids: getTeamsForRequest(teamId),
          qualification: qualification && qualification?.value !== 'all' ? qualification.value : undefined,
          status: selectedStatusId !== 'all' ? selectedStatusId : undefined,
        },
      });
      setTotal(res.data.count);
      setNewData({ newData: res.data.items, offset });
      setInitialLoadingStatusesTrue({ offset, pageSize: GRID_PAGE_SIZE, itemStatusMap });
    } catch (e) {
      handleServerError(e);
    } finally {
      setLoadingFalse();
    }
  };

  useEffect(() => {
    getTeamQualifications();
  }, [
    selectedCompanyId,
    qualification,
    selectedStatusId,
    offset,
    teamId,
    selectedTeamOptions,
    unselectedTeamOptions,
  ]);

  useEffect(() => {
    getWidgetSelectorsData();
  }, [selectedCompanyId]);

  useEffect(() => {
    if (selectedTeamOptions.length) {
      methods.setValue('teamId', selectedTeamOptions[0].value);
    } else {
      methods.setValue('teamId', unselectedTeamOptions[0].value);
    }
  }, [selectedTeamOptions, unselectedTeamOptions]);

  return {
    teamQualifications: data,
    total,
    loading,
    selectedTeamOptions,
    unselectedTeamOptions,
    qualificationsSelectOptions,
    selectedStatusId,
    methods,
    isLoadingList,
    setSelectedStatusId,
    resetLoadingList,
    getTeamQualifications,
  };
};
