import { WidgetWithData } from '@vyce/core/src/modules';
import { WidgetModuleProvider } from '@vyce/core/src/contexts';
import { LocationAndTeamsFilterState, WidgetItem } from '@vyce/core/src/types';
import { WidgetWrapper, WidgetWrapperProps } from '@vyce/core/src/components/Dashboard/WidgetWrapper';

import { useWidgetData } from './hooks';
import { useMemo } from 'react';

interface Props {
  widget: WidgetItem;
  redirectUrl?: string;
  mainDashboardFilters?: LocationAndTeamsFilterState;
}

export const WidgetModule = ({
  widget,
  redirectUrl,
  mainDashboardFilters,
  ...rest
}: Props & WidgetWrapperProps) => {
  const data = useWidgetData();

  const contextOptions = useMemo(
    () => ({ ...data, widget, redirectUrl, mainDashboardFilters }),
    [data, widget, redirectUrl, mainDashboardFilters]
  );

  return (
    <WidgetModuleProvider value={contextOptions}>
      <WidgetWrapper {...rest}>
        <WidgetWithData />
      </WidgetWrapper>
    </WidgetModuleProvider>
  );
};
