import { useCallback, useEffect, useState, useContext } from 'react';

import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { ComputedDatum } from '@nivo/pie';

import { axios } from '@vyce/core/src/api';
import { WidgetModuleContext } from '@vyce/core/src/contexts';
import { generateQueryParamsFromObj } from '@vyce/core/src/utils/url';
import { useMainDashboardFilter } from '../../../hooks/useMainDashboardFilter';

export const useWidgetData = () => {
  const [widgetData, setWidgetData] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  const { widget, selectedCompanyId, redirectUrl, mainDashboardFilters } = useContext(WidgetModuleContext);
  const history = useHistory();

  const { url: widgetUrl, type: widgetType, system_name: widgetSystemName = '' } = widget;

  const { selectedTeamOptions, getTeamsForRequest } = useMainDashboardFilter({
    mainDashboardFilters,
  });

  const getData = useCallback(
    async (url: string) => {
      if (!selectedCompanyId) {
        return setLoading(false);
      }
      try {
        const requestUrl = `/${url.replace('{company_id}', selectedCompanyId)}`;
        const res = await axios.post(requestUrl, {
          limit: 100,
          team_ids: getTeamsForRequest(selectedTeamOptions.length ? 'selected' : 'all'),
        });
        setLoading(false);
        setWidgetData(res.data.data);
      } catch (e) {
        setLoading(false);
        console.error(e);
      }
    },
    [selectedCompanyId, getTeamsForRequest]
  );

  const handleChartClick = (datum: ComputedDatum<any>) => {
    if (redirectUrl) {
      const search = history.location.search;
      const currentFilters = queryString.parse(search, { parseNumbers: true });
      const newFilters = datum.data.query_field;
      const query = generateQueryParamsFromObj({ ...currentFilters, ...newFilters });
      history.push(`${redirectUrl}?${query}`);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (widget.url && isMounted) {
      getData(widget.url);
    }
    return () => {
      isMounted = false;
    };
  }, [selectedCompanyId, selectedTeamOptions]);

  return {
    data: widgetData,
    loading,
    widgetUrl,
    widgetSystemName,
    selectedCompanyId,
    widgetType,
    handleChartClick,
  };
};
