import { useContext } from 'react';

import { Box, Typography, Button, Divider } from '@material-ui/core';
import { FiRefreshCw } from 'react-icons/fi';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { AppAccordion } from '@vyce/core/src/components/AppAccordion';

import { FieldsFunctionsContext } from './FieldsFunctionsContext';
import { TimeLogsDetailBlock } from './TimeLogsDetailBlock';
import { LocationDetailBlock } from './LocationDetailBlock';
import { DetailedSummaryBlock } from './DetailedSummaryBlock';

export const CustomiseTimeLogExportColumns = () => {
  const { restoreData } = useContext(FieldsFunctionsContext);

  return (
    <AppAccordion
      title="Customise Time Log Export columns"
      renderDetailsBlock={() => (
        <>
          <Box width="90%" marginBottom={4} display="flex" flexDirection="column" gridGap={8}>
            <Typography variant="caption">
              When exporting Time Logs, by default Vyce provides you with an awesome spreadsheet that
              summarises the Time Logs for a particular period. If you need some specific formatting for your
              Time Log export you can create a Custom Time Log Export template here.
            </Typography>
            <Typography variant="caption">
              Simply drag and position each field in the column order you’d like them on your spreadsheet.
              Only selected fields will be included in the exported file.
            </Typography>
            <Typography variant="caption">
              You can add and edit your custom fields in the{' '}
              <Typography variant="subtitle2" color="primary" display="inline">
                Custom Location Details Fields
              </Typography>{' '}
              section here in Settings.
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" gridGap={16}>
            <DndProvider backend={HTML5Backend}>
              <LocationDetailBlock />
              <Divider />
              <TimeLogsDetailBlock />
              <Divider />
              <DetailedSummaryBlock />
            </DndProvider>
          </Box>

          <Box marginTop={3}>
            <Button
              endIcon={<FiRefreshCw />}
              variant="outlined"
              color="primary"
              size="small"
              onClick={restoreData}>
              Restore to default
            </Button>
          </Box>
        </>
      )}
    />
  );
};
