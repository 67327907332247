import { useEffect, useMemo, useState } from 'react';

import { LocationAndTeamsFilterState, LocationAndTeamsOption } from '../types';

type Options = {
  value: string;
  name: string;
};
const defaultLocationOption = { value: 'all', name: 'All Locations' };
const defaultSelectedLocationOption = { value: 'selected', name: 'Selected Locations' };
const defaultTeamOption = { value: 'all', name: 'All Teams' };
const defaultSelectedTeamOption = { value: 'selected', name: 'Selected Teams' };

const getSelectedOptions = ({
  options,
  defaultOption,
  defaultSelectedOption,
}: {
  options: LocationAndTeamsOption[];
  defaultOption: Options;
  defaultSelectedOption: Options;
}) => {
  if (options.length === 0) return [defaultOption];

  const selectedLocations = options
    .filter(item => item.is_active)
    .map(item => ({ value: item.uuid, name: item.name }));

  if (selectedLocations.length === 0) return [];

  return [
    selectedLocations.length !== options.length ? defaultSelectedOption : defaultOption,
    ...selectedLocations,
  ];
};

const getUnselectedOptions = ({
  options,
  defaultOption,
}: {
  options: LocationAndTeamsOption[];
  defaultOption: Options;
}) => {
  const unselectedLocations = options
    .filter(item => !item.is_active)
    .map(item => ({ value: item.uuid, name: item.name }));

  return unselectedLocations.length ? [defaultOption, ...unselectedLocations] : unselectedLocations;
};

/**
 * Fetches teams and locations options for the selected company id.
 *
 * @param locationOption - default location option
 * @param teamOption - default team option
 * @param selectedLocationOption - option for selected locations
 * @param selectedTeamOption - option for selected teams
 *
 * @returns object with selected and unselected location and team options, and
 * functions to get locations and teams for the request based on the selected
 * option.
 */
export const useMainDashboardFilter = ({
  mainDashboardFilters,
  locationOption = defaultLocationOption,
  teamOption = defaultTeamOption,
  selectedLocationOption = defaultSelectedLocationOption,
  selectedTeamOption = defaultSelectedTeamOption,
}: {
  locationOption?: Options;
  selectedLocationOption?: Options;
  teamOption?: Options;
  selectedTeamOption?: Options;
  mainDashboardFilters: LocationAndTeamsFilterState | undefined;
}) => {
  const [locations, setLocations] = useState<LocationAndTeamsOption[]>([]);
  const [teams, setTeams] = useState<LocationAndTeamsOption[]>([]);

  const selectedLocationOptions = useMemo(() => {
    return getSelectedOptions({
      options: locations,
      defaultOption: locationOption,
      defaultSelectedOption: selectedLocationOption,
    });
  }, [locationOption, locations, selectedLocationOption]);

  const unselectedLocationOptions = useMemo(() => {
    return getUnselectedOptions({
      options: locations,
      defaultOption: locationOption,
    });
  }, [locationOption, locations]);

  const selectedTeamOptions = useMemo(() => {
    return getSelectedOptions({
      options: teams,
      defaultOption: teamOption,
      defaultSelectedOption: selectedTeamOption,
    });
  }, [selectedTeamOption, teamOption, teams]);

  const unselectedTeamOptions = useMemo(() => {
    return getUnselectedOptions({
      options: teams,
      defaultOption: teamOption,
    });
  }, [teamOption, teams]);

  const getLocationsForRequest = (selectedLocation: 'all' | 'selected' | string) => {
    if (selectedLocation === 'all') {
      return undefined;
    }

    if (selectedLocation === 'selected') {
      return selectedLocationOptions
        .map(item => item.value)
        .filter(item => item !== selectedLocationOption.value && item !== locationOption.value);
    }

    return [selectedLocation];
  };

  const getTeamsForRequest = (selectedTeam: 'all' | 'selected' | string) => {
    if (selectedTeam === 'all') {
      return undefined;
    }

    if (selectedTeam === 'selected') {
      return selectedTeamOptions
        .map(item => item.value)
        .filter(item => item !== selectedTeamOption.value && item !== teamOption.value);
    }

    return [selectedTeam];
  };

  useEffect(() => {
    if (mainDashboardFilters) {
      setLocations(mainDashboardFilters.locations);
      setTeams(mainDashboardFilters.teams);
    }
  }, [mainDashboardFilters]);

  return {
    selectedLocationOptions,
    unselectedLocationOptions,
    selectedTeamOptions,
    unselectedTeamOptions,
    getLocationsForRequest,
    getTeamsForRequest,
  };
};
