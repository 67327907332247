import { Box, Paper, Typography } from '@material-ui/core';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { AppAccordion } from '@vyce/core/src/components/AppAccordion';

import { useStyles } from './styles';
import { useData } from './hooks';
import { Props } from './types';
import { CustomDetailsFieldsProvider, BlockOfFields, DeleteDialog } from './components';

/* 
  Module for Custom Location Details Fields in T&A settings page
*/

export const CustomDetailsFieldsModule = (props: Props) => {
  const classes = useStyles();

  const {
    defaultFields,
    customFields,
    contextOptions,
    showDeleteDialog,
    fieldName,
    closeDeleteDialog,
    deleteCustomField,
  } = useData(props);

  return (
    <CustomDetailsFieldsProvider value={contextOptions}>
      <Paper variant="outlined" className={classes.wrapper}>
        <Box marginBottom={3}>
          <Typography variant="h6">Custom Location Details Fields</Typography>
        </Box>

        <Box className={classes.controlsContainer}>
          <AppAccordion
            title="Customise Location Details"
            renderDetailsBlock={() => (
              <>
                <Box width="90%" marginBottom={2}>
                  <Typography variant="caption">
                    If you’d like to add custom fields to your Locations, you may do so here. You can then
                    populate these new fields on each ‘Location Details’ section.
                  </Typography>
                </Box>
                <DndProvider backend={HTML5Backend}>
                  <BlockOfFields defaultFields={defaultFields} customFields={customFields} />
                </DndProvider>
              </>
            )}
          />
        </Box>
      </Paper>
      <DeleteDialog
        isOpen={showDeleteDialog}
        fieldName={fieldName}
        onClose={closeDeleteDialog}
        onClick={deleteCustomField}
      />
    </CustomDetailsFieldsProvider>
  );
};
