import { useEffect, useMemo, useState, useRef } from 'react';

import { useHistory } from 'react-router-dom';
import { ComputedDatum } from '@nivo/bar';
import debounce from 'lodash/debounce';

import { getHealthAndSafetyStatsRequest } from '@vyce/core/src/api/dashboards';
import {
  getSurveysRequest,
  getSurveysSettings,
  createOrUpdateSurveysSettingRequests,
  getSurveyList,
} from '@vyce/core/src/api/survey';
import { useBooleanState } from '@vyce/core/src/hooks';

import { splitSize, widths, limits, barBlockCount } from '../config';
import { setRandomColor, splitArray } from '../utils';
import { EnrichedItem, Props } from '../types';

export const useWidgetData = ({ companyId, teamIds }: Props) => {
  const [fullData, setFullData] = useState<EnrichedItem[]>([]);
  const [page, setPage] = useState(0);
  const [loading, setLoadingTrue, setLoadingFalse] = useBooleanState(true);
  const [isOpenFilterDialog, setFilterDialogOpen, setFilterDialogFalse] = useBooleanState(false);
  const [limit, setLimit] = useState(limits.bigLimit);
  const [surveyId, setSurveyId] = useState('');
  const [excludedSurveySettings, setExcludedSurveySettings] = useState<string[]>([]);
  const [surveyList, setSurveyList] = useState<Record<string, boolean>>({});
  const history = useHistory();

  const preparedValuesForSettingForm = useMemo(() => {
    const newSurveyList = { ...surveyList };
    excludedSurveySettings.forEach(item => (newSurveyList[item] = false));
    return newSurveyList;
  }, [excludedSurveySettings, surveyList]);

  const ref = useRef<HTMLDivElement>(null);

  const cuttedData = useMemo(
    () => fullData.slice(page * barBlockCount, page * barBlockCount + limit),
    [fullData, page, limit]
  );
  const splitedData = useMemo(() => splitArray(cuttedData, splitSize), [cuttedData]);
  const maxPage = useMemo(() => Math.floor(fullData.length / limit), [limit, fullData]);

  const decreasePage = () => setPage(page - 1);
  const increasePage = () => setPage(page + 1);

  const handleBarClick = (data: ComputedDatum<EnrichedItem> & { color: string }) => {
    const {
      query_field: { survey_tag },
    } = data.data;

    history.push(`/teams/members?survey_tag=${survey_tag}`);
  };

  const fetchSurveyId = async () => {
    try {
      const { data: surveysData } = await getSurveysRequest();
      const surveyId = surveysData.items.find(item => item.name === 'Health & Safety')?.uuid ?? '';
      setSurveyId(surveyId);
    } catch (e) {
      console.error(e);
    }
  };

  const loadData = async () => {
    if (!companyId || !surveyId) return;

    try {
      const { data } = await getSurveysSettings({
        companyId,
        surveyId,
      });
      setExcludedSurveySettings(data.data.excluded_tags);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchSurveyList = async () => {
    try {
      const { data } = await getSurveyList();
      const transformedSurveyList = data.items.reduce<Record<string, boolean>>((sum, curr) => {
        sum[curr.name] = true;
        return sum;
      }, {});
      setSurveyList(transformedSurveyList);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchHealthAndSafetyStats = async () => {
    if (!companyId || !surveyId) return;
    setLoadingTrue();

    try {
      const {
        data: { data },
      } = await getHealthAndSafetyStatsRequest({
        companyId,
        body: {
          limit: 999,
          team_ids: teamIds,
        },
        surveyId,
      });

      const enrichedData = setRandomColor(data);
      setFullData(enrichedData);

      setLoadingFalse();
    } catch (e) {
      console.error(e);
      setLoadingFalse();
    }
  };

  const createOrUpdateSurveysSetting = async (newExcludedTags: string[]) => {
    if (!companyId || !surveyId) return;

    try {
      await createOrUpdateSurveysSettingRequests({
        companyId,
        surveyId,
        excludedTags: newExcludedTags,
      });

      setExcludedSurveySettings(newExcludedTags);
      fetchHealthAndSafetyStats();
      setFilterDialogFalse();
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    loadData();
  }, [companyId, surveyId]);

  useEffect(() => setPage(0), [limit]);
  useEffect(() => {
    const observeTarget = ref.current;
    if (!observeTarget) return;

    const debouncedCallback = debounce(entry => {
      const { width } = entry.contentRect;
      if (width >= widths.bigWidth) {
        setLimit(limits.bigLimit);
        return;
      }
      if (width >= widths.midWidth) {
        setLimit(limits.midLimit);
        return;
      }
      setLimit(limits.smallLimit);
    }, 300);

    const resizeObserver = new ResizeObserver(entries => {
      if (!Array.isArray(entries) || !entries.length) return;

      const entry = entries[0];
      debouncedCallback(entry);
    });

    resizeObserver.observe(observeTarget);

    return () => resizeObserver.unobserve(observeTarget);
  }, [ref]);

  useEffect(() => {
    fetchHealthAndSafetyStats();
  }, [companyId, teamIds, surveyId]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      fetchSurveyId();
      fetchSurveyList();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return {
    ref,
    loading,
    cuttedData,
    splitedData,
    showDecreaseButton: page !== 0,
    showIncreaseButton: page !== maxPage,
    isOpenFilterDialog,
    preparedValuesForSettingForm,
    decreasePage,
    increasePage,
    handleBarClick,
    setFilterDialogOpen,
    setFilterDialogFalse,
    createOrUpdateSurveysSetting,
  };
};
