import { axios } from '@vyce/core/src/api';

import { LocationAndTeamsFilterDTO, LocationAndTeamsOption, UserDashboards } from '../../types';

export const getUserDashboardsRequest = (token: string) => {
  return axios.get('/dashboards');
};

export const updateUserDashboardsRequest = (token: string, dashboards: UserDashboards) => {
  return axios.put('/dashboards', dashboards);
};

export const getHealthAndSafetyStatsRequest = ({
  companyId,
  body,
  surveyId,
}: {
  companyId: string;
  body: {
    limit: number;
    team_ids?: string[];
  };
  surveyId: string;
}) => {
  return axios.post(
    `/dashboards/widgets/teams/companies/${companyId}/bar_charts/employees_by_survey_tags_stats/${surveyId}`,
    body
  );
};

export const getTeamsAndLocationsOptionsRequest = (companyId: string) => {
  return axios.get<LocationAndTeamsFilterDTO>(`/dashboards/filters/companies/${companyId}`);
};

export const updateTeamsAndLocationsOptionsRequest = ({
  companyId,
  payload,
}: {
  companyId: string;
  payload: {
    locations: LocationAndTeamsOption[];
    teams: LocationAndTeamsOption[];
  };
}) => {
  return axios.patch(`/dashboards/filters/companies/${companyId}`, payload);
};

export const resetTeamsAndLocationsOptionsRequest = (companyId: string) => {
  return axios.get<LocationAndTeamsFilterDTO>(`/dashboards/filters/companies/${companyId}/reset`);
};
